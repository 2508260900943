import React, { useState, useEffect } from "react";
import { Container } from "react-grid-system";

import api from "../../../services/api";

import Input from "../../input/input_v2";
import ModalNew from "../../modal/modalGeneric";
import Select from "../../select/select_step6";

import ArrowIcon from "../../../assets/icons/icon-arrow.svg";
import ArrowIconDisabled from "../../../assets/icons/icon-arrow-disabled.svg";

import ModalFirstArtDesk from "../../../assets/example_image_number_revenue.png";
import ModalFirstArtMobile from "../../../assets/example_image_number_revenue_mobile.png";

import ModalFirstRevenueDesk from "../../../assets/exemplo-img-primeira-receita-desk.png";
import ModalFirstRevenueMobile from "../../../assets/exemplo-img-primeira-receita-mobile.png";

import * as S from "./styles";

export const USER = "@userinfo";

const PrescriptionStepSix = ({ ...props }) => {
    const [estado, setEstado] = useState('');
    const [firstPrescription, setFirstPrescription] = useState('');
    const [numberFirstArt, setNumberFirstArt] = useState('');
    const [salvarFirst, setSalvarFirst] = useState(false);
    const [isOpenBox, setIsOpenBox] = useState(false);
    const [showModalFirst, setShowModalFirst] = useState(false);
    const [showModalFirstArt, setShowModalFirstArt] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [isSecondInputDisabled, setIsSecondInputDisabled] = useState(true);
    const [selectOptions, setSelectOptions] = useState([]);

    const getEmpresa = async () => {
        try {
            const user = JSON.parse(localStorage.getItem(USER));
            const response = await api.get(`/filiais/${user.agro_subsidiary_id}`);
            if (response) {
                const filial = response.data.data;
                setEstado(filial.estado);
            }
        } catch (error) {}
    }

    const openBoxFirst = () => {
        if ((isOpenBox === false || isOpenBox === undefined)) {
            setIsOpenBox(!isOpenBox);
        }
    }

    const openBoxFirstArrow = () => {
        setIsOpenBox(!isOpenBox);
    }

    const changeFirstPrescription = (value) => {
        if (salvarFirst === true) {
            setFirstPrescription(value);
        }
    }

    const changeNumberFirstArt = (value) => {
        if (salvarFirst === true) {
            setNumberFirstArt(value);
        }
    }

    const changeSaveFirst = (isSave) => {
        if (isSave !== salvarFirst) {
            setSalvarFirst(isSave);
        }
    }

    useEffect(() => {
        setNumberFirstArt('')

        const fetchArtOptions = async () => {
            if (firstPrescription) {
                try {
                    const response = await api.post('receitas/buscarart', {
                        complementar_mt: firstPrescription
                    });
    
                    const options = response.data.data || [];
    
                    setSelectOptions(options);
    
                    if (options.length === 1) {
                        setNumberFirstArt(options[0]);
                    }

                    setIsSecondInputDisabled(false);
                } catch (error) {
                    setSelectOptions([]); 
                    setIsSecondInputDisabled(true);
                }
            } else {
                setSelectOptions([]);
                setIsSecondInputDisabled(true);
            }
        };
    
        fetchArtOptions();
    }, [firstPrescription]);

    useEffect(() => {
        if (isOpenBox === true) {
            setIsOpenBox(false);
        }
    }, []);

    useEffect(() => {
        props.update({ firstPrescription, numberFirstArt });
    }, [firstPrescription, numberFirstArt]);

    useEffect(() => {
        if (salvarFirst === false) {
            setFirstPrescription('');
            setNumberFirstArt('');
        }
    }, [salvarFirst]);

    useEffect(() => {
        getEmpresa();
    }, []);

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const ImageRevenueDeskAndMoobile = () => {
        if (windowWidth <= 760) {
            return (
                <S.ContainerImage>
                    <img src={ModalFirstRevenueMobile} alt="Imagem de exemplo de número de receita"/>
                </S.ContainerImage>
            )
        } else {
            return(
                <S.ContainerImage>
                    <img src={ModalFirstRevenueDesk} alt="Imagem de exemplo de número de receita"/>
                </S.ContainerImage>
            )
        }
    }

    const ImageArtDeskAndMoobile = () => {
        if (windowWidth <= 760) {
            return (
                <S.ContainerImage>
                    <img src={ModalFirstArtMobile} alt="Imagem de exemplo de número de receita"/>
                </S.ContainerImage>
            )
        } else {
            return(
                <S.ContainerImage>
                    <img src={ModalFirstArtDesk} alt="Imagem de exemplo de número de receita"/>
                </S.ContainerImage>
            )
        }
    }

    return (
        <Container>
            {(props.estado === "MT") && (
                <S.FormContainer>
                    <S.Card
                        onClick={() => openBoxFirst()}
                        openBox={isOpenBox}
                        erro={false}
                        existInformation={false}
                        disabled={props.disabled}
                    >
                        <div className="row">
                            <S.Title disabled={props.disabled}>Emissão de Receita Complementar (Opcional)</S.Title>
                            {!props.disabled ? (
                                <img className="arrow" src={ArrowIcon} alt='arrow' onClick={() => openBoxFirstArrow()} />
                            ) : (
                                <img className="arrow" src={ArrowIconDisabled} alt='arrow' />
                            )}
                        </div>

                        {!isOpenBox ? (
                            <S.Paragraph disabled={props.disabled}>Informe os dados para emissão de receita complementar</S.Paragraph>
                        ) : (
                            <>
                                <S.ContainerForm>
                                    <Input
                                        name="num_first_prescription"
                                        type="text"
                                        id="num_first"
                                        label="Número da primeira receita"
                                        width={windowWidth < 991 ? '100%' : '60%'}
                                        help={true}
                                        yesNo={true}
                                        yesNoStepSix={true}
                                        onChangeYesNo={(e) => changeSaveFirst(e)}
                                        yesNoValue={salvarFirst}
                                        disabled={salvarFirst ? false : true}
                                        messageYesNo='Emitir receita complementar'
                                        helpClick={() => setShowModalFirst(true)}
                                        onChange={e => changeFirstPrescription(e)}
                                        value={firstPrescription}
                                    />

                                    <Select
                                        label="Número ART primeira receita"
                                        value={numberFirstArt}
                                        disabled={isSecondInputDisabled || !salvarFirst}
                                        onChange={(e) => changeNumberFirstArt(e.target.value)}
                                        options={selectOptions}
                                        placeholder=""
                                        onHelpClick={() => setShowModalFirstArt(true)}
                                    />
                                </S.ContainerForm>
                            </>
                        )}
                    </S.Card>
                </S.FormContainer>
            )}

            <ModalNew
                size='md'
                show={showModalFirst}
                onHide={() => setShowModalFirst(false)}
            >
                {ImageRevenueDeskAndMoobile()}

                <br />

                <p>Informe aqui o número da primeira receita emitida para este produto que será vinculada à emissão desta receita complementar</p>

                <br />

                <p><b>Obs:</b>Ao informar este campo, a receita emitida não será enviada ao SISDEV</p>
            </ModalNew>

            <ModalNew
                size='md'
                show={showModalFirstArt}
                onHide={() => setShowModalFirstArt(false)}
            >
                {ImageArtDeskAndMoobile()}

                <br />

                <p>Informe aqui o número da ART referente à receita informada no campo acima (N° da primeira receita) para à emissão dessa receita complementar.</p>

                <br />

                <p><b>Obs:</b>Ao informar este campo, a receita emitida não será enviada ao SISDEV</p>
            </ModalNew>
        </Container>
    );
};

export default PrescriptionStepSix;