import styled from "styled-components";

export const ContainerSuccess = styled.div`
  padding: 1rem;
  min-height: 100vh;
  
  gap: 10rem;
  display: flex;
  align-items: center;
  
  justify-content: center;
  background-color: #292E38;

  @media (max-width: 881px) {
    gap: 5rem;
  }

  @media (max-width: 764px) {
    flex-direction: column;
  }
`;

export const TextsContainer = styled.div`
  max-width: 26.25rem;
  
  display: flex;
  flex-direction: column;

  img {
    width: 100%;
    min-height: 2rem;
    max-width: 9.375rem;
  }

  h1 {
    margin-top: 3rem;

    font-size: 2rem;
    color: #FFFFFF;
    font-weight: 800;
    line-height: 2.6rem;
  }

  p {
    margin-top: 1rem;

    color: #CED2DC;
    font-weight: 400;
    line-height: 1.8rem;
    font-size: 1.125rem;
  }

  @media (max-width: 764px) {
    text-align: center;
    align-items: center;
  }

  @media (max-width: 370px) {
    text-align: center;
    align-items: center;

    h1 {
      font-size: 1.5rem;
    }

    p {
      font-size: 1rem;
    }
  }
`;

export const ImageEmail = styled.div`
  img {
    width: 100%;
    min-height: 15rem;
    max-width: 17.0625rem;

    @media (max-width: 764px) {
      display: none;
    }
  }
`;
