import styled from "styled-components";

export const ContainerSelect = styled.div`
  position: relative; 
  
  width: 100%;
  height: auto;
  max-width: 16.125rem;

  @media (max-width: 990px) {
    max-width: 100%;
  }
`;

export const LabelSelect = styled.label`
  left: 1.5625rem;
  position: absolute;

  width: auto;
  height: auto;

  margin-top: ${({ isFocusedOrHasValue }) => (isFocusedOrHasValue ? "-0.5rem" : "1.0625rem")};

  pointer-events: none;
  transition: all 0.2s ease-out;
  background-color: transparent;
  background-color: ${({ isFocusedOrHasValue }) => (isFocusedOrHasValue ? "#FFFFFF" : "transparent")};

  > p {
    margin: 0;

    transition: all 0.2s ease-out;
    opacity: ${({ isDisabled }) => (isDisabled ? "0.5" : "1")};

    color: #8A92A2;
    font-weight: bold;
    line-height: normal;
    font-family: 'Lato';
    font-size: 0.6875rem;
  }
`;

export const InputSelect = styled.input`
  width: 100%;
  height: 3.125rem;
  padding: 0.8125rem 1.5625rem;

  border-radius: 1.5625rem;
  border: 1px solid rgb(186, 193, 207);

  color: #303848;
  font-size: 1rem;
  font-weight: bold;
  font-family: 'Lato', sans-serif;
  
  transition: all 0.2s ease-in;
  background-color: transparent;

  &:focus {
    outline: none;
  }

  &:disabled {
    color: #8a92a2;
    background-color: #FFFFFF;
    border: 1px solid rgb(207, 213, 223);
  }

  &:focus ~ ${LabelSelect}, &:not(:placeholder-shown) ~ ${LabelSelect} {
    > p {
      color: #303848;
      font-size: 0.6875rem;
    }
  }
`;

export const HelpButton = styled.button`
  top: 1.5rem;
  right: -2.4375rem;
  position: absolute;
  
  width: 1.5rem;
  height: 1.5rem;
  
  display: flex;
  align-items: center;
  justify-content: center;
  
  border: none;
  cursor: pointer;
  border-radius: 50%;
  background-color: transparent;
  
  transform: translateY(-50%);
`;

export const OptionsList = styled.ul`
  left: 0;
  top: 100%;
  z-index: 10 !important;
  position: relative;

  padding: 0;
  width: 100%;
  max-height: 9.375rem;
  
  margin: 0;
  display: flex;
  flex-direction: column;

  overflow-y: auto;
  
  list-style: none;
  background-color: #ffffff;
  border: 0.0625rem solid #bac1cf;

  border-radius: 0.5rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
`;

export const OptionItem = styled.div`
  padding: 0.625rem 0.9375rem;
  
  font-size: 0.875rem;
  color: #303848;
  font-family: 'Lato', sans-serif;
  
  cursor: pointer;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: #f0f0f0;
  }
`;