import styled from "styled-components";

export const ContainerFooter = styled.div`
    width: 100%;
    height: auto;
    padding: 48px 48px 1.75em;
   
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 24px;
    background-color: #21262f;
    @media (max-width: 767px) {
        padding: 32px 32px 1.75em;
    }
`;

export const Wrapper = styled.div`
    width: 100%;
    height: auto;
    max-width: 71.25rem;
    gap: 44px;
    display: flex;
    flex-direction: column;
`;

export const Information = styled.div`
    width: 100%;
    height: auto;
    gap: 20px;
    display: flex;
    justify-content: space-between;
    @media (max-width: 767px) {
        gap: 32px;
        flex-direction: column;
    }
`;

export const Column = styled.div`
    width: auto;
    height: auto;
    gap: 32px;
    display: flex;
    flex-direction: column;
    h4{
        color: #FFFFFF;
        font-size: 16px;
        line-height: 16px;
        font-weight: 800;
        font-family: "Lato", Sans-serif;
        >a{
            color: #FFFFFF;
            font-size: 16px;
            line-height: 16px;
            font-weight: 800;
            font-family: "Lato", Sans-serif;
        }
    }
    >h4:nth-of-type(2),
    >h4:nth-of-type(3),
    >h4:nth-of-type(4) {
        margin: 8px 0;
    }
    >p{
        color: #8A92A2;
        font-size: 14px;
        line-height: 1.6em;
        font-weight: 400;
        font-family: "Lato", Sans-serif; 
    }
`;

export const ContainerTopics = styled.div`
    width: 100%;
    height: auto;
    gap: 24.5px;
    display: flex;
    flex-direction: column;
    >p,
    a{
        color: #8A92A2;
        font-size: 14px;
        line-height: 14px;
        font-weight: 400;
        font-family: "Lato", Sans-serif;
        >a{
            color: #589BFF;
        }
        img{
            width: 170px;
            height: auto;
         }
    }
`;

export const ContainerSocial = styled.div`
    width: 100%;
    height: auto;
    gap: 35px;
    margin-top: 27px;
    display: flex;
    flex-direction: column;
    p{
        color: #FFFFFF;
        font-family: "Lato", Sans-serif;
        font-size: 16px;
        font-weight: 800;
        line-height: 1.2em;
    }
    @media (max-width: 479px) {
        margin: 15px 0 5px;
    }
`;

export const Social = styled.div`
    width: 100%;
    height: auto;
    gap: 28px;
    display: flex;
    >a { 
        width: auto;
        height: 48px;
        display: flex;
        align-items: center;
        >i{
            color: #FFFFFF;
            font-size: 24px;
        }
    }
`;

export const ContainerApp = styled.div`
    width: 100%;
    height: auto;
    gap: 4.5px;
    display: flex;
    flex-direction: column;
    a{
        img{
            width: 170px;
            height: auto;
         }
    }
`;

export const ContainerCopy = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    p{
        text-align: center;
        color: #8A92A2;
        font-size: 12px;
        font-weight: 400;
        line-height: 1.5em;
        font-family: "Lato", Sans-serif;
    }
    @media (max-width: 767px) {
        gap:  1.75em;
        align-items: center;
        flex-direction: column;
    }
`;

export const Copy = styled.div`
    width: auto;
    height: auto;
    gap: 12px;
    display: flex;
    a, span{
        color: #8A92A2;
        font-size: 12px;
        font-weight: 400;
        line-height: 1.5em;
        font-family: "Lato", Sans-serif;
    }
`;