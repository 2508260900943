import styled from 'styled-components';

export const Container = styled.div`
    width: 100vw;
    height: 100vh;
    background-color: #292E38;

    .imagem-fundo{
        width: 100%;
        margin-top: -22%;
        position: fixed;
    }

    @media only screen and (max-width: 768px) {
        height: auto;
    }
`;

export const SessionRow = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    @media only screen and (max-width: 768px) {
        flex-direction: column;
        height: auto;
    }

`;

export const SessionRow2 = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    @media only screen and (max-width: 768px) {
        flex-direction: column;
        height: auto;
    }

`;

export const Card = styled.div`
    width: 50vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: ${props => props.backgroundColor ? props.backgroundColor : 'transparent'};
    z-index: 10;

    padding-top: 20px;

    @media only screen and (max-width: 768px) {
        width: 100vw;
        height: auto;
    }

`;

export const Card2 = styled.div`
    width: 100vw;
    height: ${props => props.backgroundColor ? '70vh' : '30vh'};
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: ${props => props.backgroundColor ? props.backgroundColor : 'transparent'};
    z-index: 10;

    padding-top: 20px;
    padding-left: 30vw;
    padding-right: 30vw;

    @media only screen and (max-width: 768px) {
        width: 100vw;
        height: ${props => props.backgroundColor ? '70vh' : 'auto'};
        padding-left: 0px;
        padding-right: 0px;
    }

`;

export const FormContainer = styled.div`
    width: 55%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .btnGoogle{
        width: 100%;
        height: 50px;
        .g-signin2{
            width: 100% !important;

            &>div{
                width: 100% !important;
                height: 48px !important;
                border: 1.6px solid #586377 !important;
                border-radius: 32px !important;
                box-shadow: 0 0 0px 0px transparent !important;
                transition: all 0.3s ease;

                &:hover {
                    box-shadow: 0 0 0px 0px transparent !important;
                    transform: scale(1.03);
                }

                &>div {
                    display: flex !important;
                    flex-direction: row !important;
                    align-items: center !important;
                }
                span{
                    margin-left: calc(50% - 16px);
                    transform: translateX(-110%);
                }
            }
        }
    }

    .link {
        text-align: end;
        font-weight: 700;
        font-size: 14px;
        font-style: normal;
        color: #589BFF;
        cursor: pointer;
    }

    .terms {
        width: 100%;
        height: auto;
        padding: 0px 50px 0px 50px;

        p {
            color: #8A92A2;
            font-family: Lato;
            font-style: normal;
            font-weight: bold;
            font-size: 11px;
            line-height: 15px;
            text-align: center;

            label {
                color: #000;
                font-weight: 700;
                cursor: pointer;
            }
        }
    }

    @media only screen and (max-width: 768px) {
        width: 90vw;
    }

`;

export const FormContainer2 = styled.div`
    width: 55%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .btnGoogle{
        width: 100%;
        height: 50px;
        .g-signin2{
            width: 100% !important;

            &>div{
                width: 100% !important;
                height: 48px !important;
                border: 1.6px solid #586377 !important;
                border-radius: 32px !important;
                box-shadow: 0 0 0px 0px transparent !important;
                transition: all 0.3s ease;

                &:hover {
                    box-shadow: 0 0 0px 0px transparent !important;
                    transform: scale(1.03);
                }

                &>div {
                    display: flex !important;
                    flex-direction: row !important;
                    align-items: center !important;
                }
                span{
                    margin-left: calc(50% - 16px);
                    transform: translateX(-110%);
                }
            }
        }

    }

    .link {
        text-align: end;
        font-weight: 700;
        font-size: 14px;
        font-style: normal;
        color: #589BFF;
        cursor: pointer;
    }

    .terms {
        width: 100%;
        height: auto;
        padding: 0px 50px 0px 50px;

        p {
            color: #8A92A2;
            font-family: Lato;
            font-style: normal;
            font-weight: bold;
            font-size: 11px;
            line-height: 15px;
            text-align: center;

            label {
                color: #000;
                font-weight: 700;
                cursor: pointer;
            }
        }
    }

    @media only screen and (max-width: 768px) {
        width: 90vw;
    }

`;

export const Line = styled.div`
  width: 100%;
  height: 47px;
  padding-top: 5px;
  padding-bottom: 0px;

  span {
    width: 16px;
    height: 15px;
    padding-left: 12px;
    padding-right: 12px;
    color: #000000;
    background-color: #FFF;
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 150%;
    position: relative;
    top: -31.5px;
    left: calc(50% - 28px/2);
  }

`;

export const ContainerLogo = styled.div`
  width: 63%;

  .logo{
    width: 100%;
  }

  .title{
    color: #FFF;
    font-family: Lato;
    font-weight: 700;
    font-style: normal;
    font-size: 28px;
    line-height: 33.6px;
  }

  .subtitle{
    color: #CED2DC;
    font-family: Lato;
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
  }

  img {
    width: 120px;
    margin-bottom: 48px;
  }

    .no-card {
        width: 231px;
        height: 32px;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        background-color: #303848;
        border: 1px solid #303848;
        border-radius: 24px;
        padding: 8px 12px;

        img{
            width: 16px;
            height: 16px;
            margin-bottom: 0px;
        }

        label {
            font-family: Lato;
            font-style: normal;
            font-weight: bold;
            font-size: 10px;
            color: #FFF;
            margin-bottom: 0px;
        }
    }

  @media only screen and (max-width: 768px) {

    margin-bottom: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .logo{
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }

    .title{
      width: 100%;
      text-align: center;
    }

    .subtitle {
      width: 100%;
      text-align: center;
    }
  }

`;

export const ContainerLogo2 = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .title{
    color: #FFF;
    font-family: Lato;
    font-weight: 700;
    font-style: normal;
    font-size: 28px;
    line-height: 33.6px;
  }

  .subtitle{
    color: #CED2DC;
    font-family: Lato;
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
  }

  img {
    width: 120px;
    margin-bottom: 48px;
  }

    .no-card {
        width: 231px;
        height: 32px;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        background-color: #303848;
        border: 1px solid #303848;
        border-radius: 24px;
        padding: 8px 12px;

        img{
            width: 16px;
            height: 16px;
            margin-bottom: 0px;
        }

        label {
            font-family: Lato;
            font-style: normal;
            font-weight: bold;
            font-size: 10px;
            color: #FFF;
            margin-bottom: 0px;
        }
    }

  @media only screen and (max-width: 768px) {

    margin-bottom: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .logo{
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }

    .title{
      width: 100%;
      text-align: center;
    }

    .subtitle {
      width: 100%;
      text-align: center;
    }
  }

`;

export const Loading = styled.div`

  @-webkit-keyframes rotating /* Safari and Chrome */ {
    from {
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
  }
    
  @keyframes rotating {
    from {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
  }


  width: 50px;
  height: 50px;
  position: relative;

  background-color: transparent;
  border-top: 1px solid #2fbb69;
  border-bottom: 1px solid #2fbb69;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  border-radius: 50%;
  -webkit-animation: rotating 0.7s linear infinite;
  -moz-animation: rotating 0.7s linear infinite;
  -ms-animation: rotating 0.7s linear infinite;
  -o-animation: rotating 0.7s linear infinite;
  animation: rotating 0.7s linear infinite;

`;

