import React from 'react';
import * as S from './style';

import GooglePLay from '../../assets/icons/disponivel-google-play.svg'
import AppStore from '../../assets/icons/disponivel-app-store.svg'

export function Footer() {
    return (
        <S.ContainerFooter>
            <S.Wrapper>
                <S.Information>
                    <S.Column>
                        <h4>Empresa</h4>

                        <S.ContainerTopics>
                            <a href="https://agroreceita.com.br/sobre-nos/">
                                Quem somos
                            </a>

                            <a href="https://agroreceita.com.br/central-de-ajuda/">
                                Central de ajuda
                            </a>

                            <a href="https://agroreceita.com.br/ajuda/">
                                Perguntas frequentes
                            </a>

                            <a href="https://agroreceita.com.br/fale-conosco/">
                                Fale conosco
                            </a>

                            <a href="https://agroreceita.com.br/fale-com-especialista/">
                                Solicite um especialista
                            </a>
                        </S.ContainerTopics>

                        <h4>
                            <a href="https://agroreceita.com.br/seja-um-parceiro">
                                Seja um Parceiro
                            </a>
                        </h4>

                        <h4>
                            <a href="https://agroreceita.com.br/materiais/">
                                Materiais gratuitos
                            </a>
                        </h4>

                        <h4>
                            <a href="https://agroreceita.com.br/blog/">
                                Blog
                            </a>
                        </h4>
                    </S.Column>

                    <S.Column>
                        <h4>Funcionalidades</h4>

                        <S.ContainerTopics>
                            <a href='https://agroreceita.com.br/receita-agronomica/'>
                                Receita Agronômica
                            </a>

                            <a href='https://sistema.agroreceita.com.br/cadastro'>
                                Compêndio Agrícola Gratuito
                            </a>

                            <a href='http://agroreceita.com.br/aplicativo-compendio-agricola'>
                                Aplicativo Compêndio Agrícola
                            </a>

                            <a href='https://agroreceita.com.br/aplicativo-receituario-agronomico'>
                                Aplicativo Receituário Agronômico
                            </a>

                            <a href='https://agroreceita.com.br/art-de-receituario-agronomico/'>
                                ART – Anotação de Responsabilidade Técnica
                            </a>

                            <a href='https://agroreceita.com.br/trt-de-receituario-agronomico/'>
                                TRT – Termo de Responsabilidade Técnica
                            </a>

                            <a href='https://agroreceita.com.br/sistema-integracao-receituario-agronomico'>
                                API de Integração
                            </a>

                            <a href='https://agroreceita.com.br/cadastro-de-clientes'>
                                Cadastro de Clientes
                            </a>

                            <a href='https://agroreceita.com.br/assinatura-digital-e-eletronica/'>
                                Assinatura Eletrônica 
                            </a>

                            <a href='https://agroreceita.com.br/previsao-do-tempo'>
                                Previsão do Tempo
                            </a>

                            <a href='https://agroreceita.com.br/ficha-emergencia-fispq-fds/'>
                                FDS (FISPQ) e Ficha de Emergência
                            </a>

                            <a href='https://agroreceita.com.br/homologacao-defesas-agropecuarias/'>
                                Homologação junto às Defesas
                            </a>

                            <a href='https://agroreceita.com.br/relatorios-receituario-agronomico/'>
                                Métricas Essenciais
                            </a>

                            <a href='https://agroreceita.com.br/crm/'>
                                CRM e Agenda para o Agro
                            </a>
                        </S.ContainerTopics>
                    </S.Column>

                    <S.Column>
                        <h4>Planos</h4>

                        <S.ContainerTopics>
                             <a href='https://agroreceita.com.br/planos/'>
                                Plano Basic
                             </a>

                             <a href='https://agroreceita.com.br/planos/'>
                                Plano Light
                             </a>

                             <a href='https://agroreceita.com.br/planos/'>
                                Plano Pro
                             </a>

                             <a href='https://agroreceita.com.br/pre-pago'>
                                Plano Pré-Pago
                             </a>
                        </S.ContainerTopics>

                        <S.ContainerSocial>
                            <p>Redes Sociais</p>

                            <S.Social>
                                <a href="https://www.instagram.com/agroreceita" target="_blank">
                                    <span class="elementor-screen-only" />

                                    <i class="fab fa-instagram"></i>
                                </a>

                                <a href="https://www.facebook.com/AgroReceita" target="_blank">
                                    <span class="elementor-screen-only" />

                                    <i class="fab fa-facebook"></i>
                                </a>

                                <a href="https://www.linkedin.com/company/agroreceita" target="_blank">
                                    <span class="elementor-screen-only" />

                                    <i class="fab fa-linkedin"></i>
                                </a>

                                <a href="https://youtube.com/@agroreceita" target="_blank">
                                    <span class="elementor-screen-only" />

                                    <i class="fab fa-youtube"></i>
                                </a>
                            </S.Social>
                        </S.ContainerSocial>
                    </S.Column>

                    <S.Column>
                        <h4>Contato</h4>

                        <S.ContainerTopics>
                            <p>
                                <a href='mailto:vendas@agroreceita.com.br'>vendas@agroreceita.com.br</a>
                            </p>
                        </S.ContainerTopics>

                        <S.ContainerTopics>
                            <p>
                                <a href='tel:+551532729780'>(15) 3272-9780</a>

                                &nbsp; / &nbsp;

                                <a href='tel:+5515981657739'>(15) 98165-7739</a>
                            </p>
                        </S.ContainerTopics>

                        <p>R. Rosa Lotfi de Almeida Bueno, 155 Vila <br />Nastri II, Itapetininga (SP). CEP: 18.206-390</p>

                        <p>R. Isidoro Garcês, 77-3 Rio Tavares, <br />Florianópolis (SC). CEP: 88.048-444</p>

                        <S.ContainerApp>
                            <a href='https://play.google.com/store/apps/developer?id=AgroReceita'>
                                <img src={GooglePLay} alt='' />
                            </a>

                            <a href='https://apps.apple.com/us/app/agroreceita-receita-agron%C3%B4mica/id1671808311'>
                                <img src={AppStore} alt='' />
                            </a>
                        </S.ContainerApp>
                    </S.Column>
                </S.Information>

                <S.ContainerCopy>
                    <S.Copy>
                        <a href='https://agroreceita.com.br/politica-de-privacidade/'>Política de Privacidade</a>

                        <span>|</span>

                        <a href='https://agroreceita.com.br/termos-de-uso/'>Termos de Uso</a>
                    </S.Copy>

                    <p>2020-2024 AgroReceita ® © Todos os direitos reservados</p>
                </S.ContainerCopy>
            </S.Wrapper>
        </S.ContainerFooter>
    )
}

export default Footer;