import React, { useState, useRef, useEffect } from "react";

import IconHelp from "../../assets/icons/help";

import * as S from "./styles_step6";

const Select = ({
  label,
  value,
  disabled,
  onChange,
  options,
  placeholder,
  onHelpClick,
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [hasValue, setHasValue] = useState(!!value);
  const [filteredOptions, setFilteredOptions] = useState(options);
  const inputRef = useRef(null);
  const optionsListRef = useRef(null);

  const handleFocus = () => {
    if (!disabled) {
      setIsFocused(true);
      setFilteredOptions(options);
    }
  };

  const handleBlur = (e) => {
    if (
      optionsListRef.current &&
      !optionsListRef.current.contains(e.relatedTarget) &&
      !inputRef.current.contains(e.relatedTarget)
    ) {
      setIsFocused(false);
    }
  };

  const handleChange = (e) => {
    const inputValue = e.target.value;

    setHasValue(inputValue.trim() !== "");

    if (onChange) onChange(e);

    const filtered = options.filter((option) =>
      option.toLowerCase().includes(inputValue.toLowerCase())
    );
    setFilteredOptions(filtered);
  };

  const handleSelectOption = (option) => {
    if (onChange) {
      onChange({ target: { value: option } });
    }
    setIsFocused(false);
    setHasValue(true);
  };

  useEffect(() => {
    if (value.trim() !== "") {
      setHasValue(true);
    } else {
      setHasValue(false);
    }
  }, [value]);

  return (
    <S.ContainerSelect>
      <S.LabelSelect
        isFocusedOrHasValue={isFocused || hasValue}
        isDisabled={disabled}
      >
        <p>{label}</p>
      </S.LabelSelect>

      <S.InputSelect
        ref={inputRef}
        type="text"
        value={value}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={handleChange}
        disabled={disabled}
        placeholder={isFocused ? placeholder : ""}
      />

      <S.HelpButton onClick={onHelpClick}>
        <IconHelp fill="#5DA8FD" cursor="pointer" />
      </S.HelpButton>

      {isFocused && filteredOptions.length > 0 && (
        <S.OptionsList ref={optionsListRef}>
          {filteredOptions.map((option, index) => (
            <S.OptionItem
              key={index}
              tabIndex={0}
              onClick={(e) => {
                e.stopPropagation();
                handleSelectOption(option);
              }}
            >
              {option}
            </S.OptionItem>
          ))}
        </S.OptionsList>
      )}
    </S.ContainerSelect>
  );
};

export default Select;
