import styled from 'styled-components';

export const MainContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    padding: 3.125rem 0;
    flex-direction: column;
    border-radius: 1.625rem;
    justify-content: center;

    .ContainerTable {
        opacity: 1;
        background-color: rgba(255, 255, 255, 0.9);
    }

    &:has(.receituario) {
        background-size: cover;
        background-color: #292E38;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url('https://agroreceita.com.br/wp-content/uploads/2020/09/si%CC%81mbolo-branco-1.webp');
    }
`;

export const Title = styled.h2`
    color: #ffff;
    font-size: 2rem;
    font-weight: 900;
    text-align: center;
    margin-bottom: 0.5rem;

    ${({ className }) =>
        className === 'plans' &&
        `
        color: #21262F;
    `}

    @media (max-width: 570px) {
        font-size: 1.5rem;
    }
`;

export const Subtitle = styled.p`
    margin-bottom: 2rem;
    padding: 1rem;
    
    color: #dfe4eb;
    font-size: 1.1rem;
    text-align: center;

    ${({ className }) =>
        className === 'plans' &&
        `
        color: #303848;
    `}

    @media (max-width: 570px) {
        font-size: 1rem;
    }
`;


export const ContainerTable = styled.div`
    width: 100%;
    margin: auto;
    max-width: ${({ isPrepaid }) => (isPrepaid ? '30rem' : '960px')};
    border-radius: 1rem;
    background-color: #fff;

    @media (max-width: 1237px) {
        border-radius: 0;
    }
`;

export const Table = styled.table`
    width: 100%;
    font-size: 1rem;
    border-collapse: collapse;
    font-family: 'lato', sans-serif;
    table-layout: unset; // Garante que as larguras das colunas sejam fixas

    @media (max-width: 767px) {
        table-layout: fixed;
    }

    @media (max-width: 470px) {
        display: flex;
        flex-direction: column;
    }

    @media (max-width: 570px) {
        font-size: 1rem;
        table-layout: fixed;
    }
`;

export const TableHead = styled.thead`
    background-color: #FFFFFF;

    & tr:nth-child(1) {
        background-color: #fFFFFF !important;
    }

    @media (max-width: 470px) {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0.5rem 0;
    }
`;

export const TableBody = styled.tbody`

`;

export const TableRow = styled.tr`
    display: table-row;

    &:nth-child(odd) {
        background-color: #f9f9f9;
    }

    @media (max-width: 570px) {
        font-size: 0.9rem;
        padding: 0.313rem;
    }

 
`;

export const TableHeader = styled.th`
    padding: 1.875rem;
    color: #333;
    font-weight: 900;
    font-size: 1.25rem;
    text-align: center;

    .icon {
        display: flex;
        justify-content: start;
    }

    :first-child {
        border-top-left-radius: 0.625rem;
    }

    :last-child {
        border-top-right-radius: 0.625rem;
    }

    @media (max-width: 767px) {
        font-size: 0.875rem;
        padding: 0.5rem;
    }

    @media (max-width: 470px) {
        flex: 1; // Divide o espaço igualmente entre os cabeçalhos
        padding: 0.5rem;
        font-size: 0.875rem;

        &:nth-child(2) {
            padding-left: ${({ isPrepaid }) => (isPrepaid ? '62vw' : '36vw')};
            padding-right: 0;
        }

        &:nth-child(3) {
            padding-right: 0;
        }

        &:nth-child(4) {
            padding-right: 0;
        }
    }
`;

export const TableCell = styled.td`
    padding: 0.6rem;
    color: #333;
    font-size: 0.75rem;
    text-align: center;

    &:first-child {
        text-align: left;
        font-weight: 700;
        padding-left: 2rem;
        font-size: 0.938rem;
        width: 22%; // Define 60% da largura da tabela para descrição
        min-width: 128px; // Largura mínima para evitar quebra
        height: 57px;
    }

    &:nth-child(2),
    &:nth-child(3),
    &:last-child {
        width: 13.3%; // Cada coluna de check ocupa ~13.3% (totalizando ~40%)
        max-width: 85px; // Largura máxima para ícones
        font-size: 0.875rem;
        font-weight: 400;
    }

    &.price-prepaid {
        width: 100%;
        max-width: 1000px;
    }


    @media (max-width: 767px) {
        padding: 0.5rem;
        font-size: 0.75rem;
        font-weight: 800;

        &:first-child {
            padding-left: 0.4rem;
            font-size: 0.770rem;
            max-width: 100px; // Mantém o mínimo de 45% em telas menores
            width: 100%; // Mantém a largura fixa como 45% em telas menores
        }

        &:nth-child(2) {
            font-size: 0.75rem;
            font-weight: 400;
        }

        &:nth-child(3) {
            font-size: 0.75rem;
            font-weight: 400;
        }

        &:last-child {
            font-size: 0.75rem;
            font-weight: 400;
        }

        &.support-row {
            min-width: 100px;
        }

        img {
            width: 48%;
        }

    @media (max-width: 470px) {
        &:first-child {
            width: ${({ isPrepaid }) => (isPrepaid ? '83%' : '48%')};
        }

        img { 
            width: ${({ isPrepaid }) => (isPrepaid ? '48%' : '48%')};
        }
    }
}
`;

export const SupportRow = styled.tr`
    background-color: white;
    justify-items: center !important;
    
    
  ${({ isPrepaid }) =>
        !isPrepaid &&
        `
    @media (max-width: 767px) {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      width: 97vw;
      gap: 0.2rem;
      flex-direction: row;
      justify-content: space-between;
      align-content: center
      

      td:first-child {
        display: none;
      }

      td {
        width: 100%;
        padding: 0.5rem 0;
      }
    }
  `}
`;

export const SupportTitle = styled.h4`
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 0.5rem;

  @media (max-width: 767px) {
    display: ${({ isPrepaid }) => (isPrepaid ? 'block' : 'block')};
  }
`;

export const SupportPlanName = styled.div`
  display: none;

  @media (max-width: 767px) {
    display: ${({ isPrepaid }) => (isPrepaid ? 'none' : 'block')};
    width: 100%;
    font-weight: bold;
    text-align: center;
    margin-bottom: 0.313rem;
    padding: 0.5rem 0;
  }
`;

export const SupportList = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;

  @media (max-width: 767px) {
    width: 100%;
    font-size: 0.625rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    gap: 0.2rem;
    font-weight: 400;
  }
`;

export const SupportItem = styled.li`
  color: #666;
  font-size: 0.9rem;

  @media (max-width: 767px) {
    width: 100%;
    font-size: 0.625rem;
    text-align: center;
  }
`;

export const PriceRow = styled.tr`
    
    @media (max-width: 1237px) {
        display: ${({ isPrepaid }) => (isPrepaid ? 'flex' : 'none')};
        flex-direction: ${({ isPrepaid }) => (isPrepaid ? 'column' : '')};
        align-items: ${({ isPrepaid }) => (isPrepaid ? 'center' : '')};
    }
`;

export const PriceWithButtonContainer = styled.div`
    width: 100%;
    display: flex;
    padding: 0.625rem;
    
    align-items: center;
    box-sizing: border-box;
    flex-direction: column;
    justify-content: center;

    @media (max-width: 1237px) {
        width: ${({ isPrepaid }) => (isPrepaid ? '202%' : '100%')};
        padding: 0.625rem;
        max-width: 1000px;
    }

    @media (max-width: 570px) {
        width: ${({ isPrepaid }) => (isPrepaid ? '100%' : '100%')};
        padding: 0.5rem;
        max-width: 1000px;

        .price-prepaid {
            width: 100%;
            max-width: 1000px !important;
        }
    }

    @media (max-width: 320px) {
        padding: 0.313rem;
    }
`;


export const MobilePriceContainer = styled.div`
    display: none;

    @media (max-width: 1237px) {
        width: 100%;
        display: flex;
        padding: 0;
        
        flex-direction: row;
        justify-content: space-between;
        background-color: #f9f9f9;
    }
`;

export const MobilePriceBox = styled.div`
    display: flex;
    width: 100%;
    height: 9.875rem;
    max-width: 100%;
    padding: 1.5rem 0.5rem 1rem;
    
    text-align: center;
    align-items: center;
    box-sizing: border-box;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
    background-color: ${props => (props.positionMiddle ? "#292E38" : "#303848")};

    @media (max-width: 570px) {
        height: 12.5rem;
    }
`;

export const MobilePriceBoxTitle = styled.h4`
    color: white;
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 0.625rem;

    @media (max-width: 1237px) {
        font-size: 1rem;
    }
`;

// Para black
export const OldPrice = styled.div`
    font-size: ${({ isMobile }) => (isMobile ? '0.875rem' : '1rem')};
    color: #888;
    text-decoration: line-through;
    margin-bottom: 4px;
`;

export const NewPrice = styled.div`
    font-size: ${({ isMobile }) => (isMobile ? '1.5rem' : '1.75rem')};
    font-weight: 800;
    color: ${({ isMobile }) => (isMobile ? '#FF5252' : '#FF5252')};
    margin-bottom: 4px;

    @media (max-width: 386px) {
        font-size: ${({ isMobile }) => (isMobile ? '1.2rem' : '1.75rem')};
    }
    .currency-symbol {
         font-size: 1.2rem;
         vertical-align: baseline;

         @media (max-width: 479px) {
            font-size: 0.8rem;
         }
     }

    .cents {
        font-size: 1.2rem;
        vertical-align: baseline;
    }
`;

// Descomentar pós Black
export const Price = styled.div`
    color: #333;
    font-weight: 900;
    font-size: 2rem;
    font-size: 2.5rem;
    color: ${props => (props.isMobile ? "#DFE4EB" : "#21262F")};
    
    .currency-symbol {
        font-size: 1.2rem;
        vertical-align: baseline;
    }

    .cents {
        font-size: 1.2rem;
        vertical-align: baseline;
    }

    @media (max-width: 1237px) {
        padding: 0.938rem;
        font-size: ${({ isPrepaid }) => (isPrepaid ? '2rem' : '1rem')};
    }

    @media (max-width: 386px) {
        font-size: ${({ isPrepaid }) => (isPrepaid ? '2rem' : '1rem')};
    }
`;

export const PriceDescription = styled.p`
    margin-top: 1px;
    font-size: 0.8rem;
    text-align: center;
    font-weight: 600;
    
    color: ${props => (props.isMobile ? "#BAC1CF" : "#21262F")}; 

    &:nth-child(2) {
        color: ${props => (props.isMobile ? "#21262F" : "#21262F")}; 
    }
    
    &:nth-child(3) {
        color: ${props => (props.isMobile ? "#BAC1CF" : "#FF5252")};
    }

    &:nth-child(4) {
        color: ${props => (props.isMobile ? "#FF5252" : "#FF5252")};
    }

    @media (max-width: 1237px) {
        font-size: 0.8rem;
    }

    @media (max-width: 570px) {
        font-size: 0.7rem;
    }
`;

export const ButtonBelowPrices = styled.div`
    display: none;

    @media (max-width: 1237px) {
        width: 100%;
        display: flex;
        
        justify-content: center;
        background-color: #fff;
    }
`;

export const AdditionalText = styled.p`
    margin: 0;
    padding: 0.625rem 0;
    
    color: #555;
    font-size: 0.9rem;
    text-align: center;
    @media (max-width: 1237px) {
        display: ${({ isPrepaid }) => (isPrepaid ? 'none' : 'none')};
    }
`;

export const MobileAdditionalTextContainer = styled.div`
    display: none;

    @media (max-width: 1237px) {
        margin: 0;
        width: 100%;
        display: flex;
        padding: 1.25rem;
        
        color: #555;
        font-size: 0.9rem;
        text-align: center;
        box-sizing: border-box;
        background-color: #fff;
        justify-content: flex-start;
        border-bottom-left-radius: 1rem;
        border-bottom-right-radius: 1rem;
    }
`;

export const MobileAdditionalText = styled.p`
    color: #555;
    text-align: left;
    font-size: 78%;
`;

export const PrePaidPlanContainer = styled.div`
    padding: 1.25rem;
    margin: 1.25rem;
    
    color: #03903E;
    font-size: 95%;
    font-weight: 600;
    text-align: center;
    border-radius: 0.5rem;
    box-sizing: border-box;
    background-color: #d4edda;

    button {
        all: unset;
        cursor: pointer;
        color: #589BFF;
    }

    @media (max-width: 1237px) {
        font-size: 80%;
    }
`;

export const MobilePlans = styled.h1`
    display: flex;
    color: white;
    font-size: 1.7rem;

    @media (max-width: 479px) {
            font-size: 1.25rem;
            line-height: 1.2em;
            font-weight: 800;
            max-width: 5.438rem;
        }
`;
