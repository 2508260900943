import styled from "styled-components";

export const ContainerPlanos = styled.div`
    background-color: #FFFFFF;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-start;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 40px;
    padding-top: 30px;

    @media only screen and (max-width: 768px) {
        padding-left: 8px;
        padding-right: 8px;
        padding-bottom: 8px;
    }
`;

export const Tela = styled.div`
    width: 100vw;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

`;

export const SimulaPlanos = styled.div`
    background-color: #FFFFFF;
    width: 40%;
   
    .container{
        width: 420px;
        margin: auto;

        .valores{
            margin-top: 40px;
            .valor{
                text-align: center;
                font-family: Lato;
                font-weight: normal;
                font-size: 23px;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: #8a92a2;
            }
            .total{
                text-align: center;
                font-family: Lato;
                font-weight: normal;
                font-size: 12px;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: #8a92a2;

            }
        }

        .botoes{
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            flex-wrap: wrap;
            margin-top: 60px;
            width: 100%;
            max-width: 400px;
            button{
                margin-bottom: 20px;
            }
        }


    }
    @media only screen and (max-width: 768px){
        width: 100%;

        .button-finish {
            justify-content: space-evenly !important;
        }

        .logo{
            position: absolute;
            left: 0;
            right: 0;
            margin: auto;
            img{
                margin-left: 15px;
            }
        }

    }

    @media only screen and (max-width: 440px){
        .container{
            width: 100%;
        }

    }

`;

export const Footer = styled.div`
    width: 100%;
    position: absolute;
    bottom: 0;
    background-color: #21262f;
    margin-top: 31px;

    .contatos{
        width: 580px;
        margin: auto;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;
        color: #FFF;
        padding-top: 10px;
        padding-bottom: 10px;

        .item{
            margin-left: 20px;
            display: flex;
            flex-direction: row;
            a{
                text-decoration: none;
                color: #FFF;
                img{
                    width: 20px;
                }
                i{
                    font-size: 14px;
                    position: relative;
                    top: 2  px;
                    margin-right: 5px;
                }
            }
            i{
                font-size: 14px;
                position: relative;
                top: 5px;
                margin-right: 5px;
            }
        }
    }


    @media only screen and (max-height: 667px){
        position: relative;
        .contatos{
            width: 90%;
            max-width: 580px;
        }
    }

`;

export const Container = styled.div`
    width: 100%;
    max-width: 440px;
    margin: auto;
    height: auto;
    margin-top: ${props => props.marginTop ? props.marginTop : '48px'};
    display: flex;
    flex-direction: column;
    align-items: center;

    .text {
        font-size: 13px;
        text-align: center;
        letter-spacing: 0px;
    }
`;

export const Title = styled.h2`
    font-family: Lato;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #303848;
    white-space: nowrap;
    margin-bottom: ${props => props.marginBottom ? props.marginBottom : '16px'};
    margin-top: ${props => props.marginTop ? props.marginTop : '0px'};
`;

export const Subtitle = styled.label`
    font-family: Lato;
    font-size: 11px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #bac1cf;
    margin-top: 8px;
`;

export const HeaderPlans = styled.div`
    display: flex;
    max-width: 1100px;
    justify-content: space-between;
    margin-inline: 0;
    align-items: center;
    background-color: #FFFFFF;
    width: 100%;

    .image {
        width: 100%;
        max-width: 255px;
        
        img {
            width: 10.625rem;
            height: 2.375rem;
        }
    }

    .links {
        width: 100%;
        max-width: 255px;

        gap: 1.5rem;
        display: flex;

        font-size: 0.625rem;
        text-transform: uppercase;
        font-weight: 700;
        letter-spacing: 0.1rem;
        justify-content: center;
        
        a {
            color: #21262F;
            padding: 0.7rem;
            border-radius: 1rem;
            transition: background-color 300ms ease;
        
            &:hover {
                background-color: lightgray;

            }
        }
    }

    .buttons-plans {
        display: flex;
        gap: 10px;

        > :nth-child(1){
            all: unset;
            cursor: pointer;
            background-color: #FFFFFF;
            padding: 12px 24px;
            border: 2px solid #21262F;
            border-radius: 50px;
            font-size: 11px;
            letter-spacing: 0.1rem;
            font-weight: 800;
            text-transform: uppercase;
            color: #21262F;
            transition: scale 500ms ease;
            
            &:hover {
                scale: 1.1;
                color: #FFFFFF;
                background-color: #21262F;
            }
        }

        > :nth-child(2){
            all: unset;
            cursor: pointer;
            background-color: #2FBB69;
            padding: 16px 24px 16px 24px;
            border-radius: 50px;
            font-size: 11px;
            letter-spacing: 0.1rem;
            font-weight: 800;
            text-transform: uppercase;
            color: white;
            transition: scale 500ms ease;

            &:hover {
                scale: 1.1;
            }
        }
    }

    .mobile-only {
        display: none;
    }

    @media (max-width: 911px) {
        justify-content: space-between;
        padding: 1rem;

        .buttons-plans {
            display: none;
        }

        .links {
            display: none;
        }

        .mobile-only {
            display: flex;
            gap: 12px;
            letter-spacing: 0.1rem;
            font-size: 10px;
            text-transform: uppercase;

            a {
                color: #21262F;
                cursor: pointer;
            }
        }
    }
`