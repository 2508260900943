import React from 'react';
import { withRouter } from "react-router-dom";
import Logo from '../../assets/logo-agroreceita-branco.svg';
import Email from '../../assets/imagem-email-sucesso.svg';
import * as S from './styleSucess';

const SuccessTest = () => {
    return (
        <S.ContainerSuccess>
            <S.TextsContainer>
                <img src={Logo} alt="Imagem logo da empresa" />
                <h1>Acesse seu e-mail para validar sua conta!</h1>
                <p>Caso não encontre confira sua caixa de spam.</p>
            </S.TextsContainer>

            <S.ImageEmail>
                <img src={Email} alt="Imagem envelope de email" />
            </S.ImageEmail>
        </S.ContainerSuccess>
    );
}

export default withRouter(SuccessTest);
